<template>
  <main>
<!------------------------------------------------------------------------ ABOUT ------------------------------------------------------------------------>
    <section>
      <h1>About the Message Board</h1>

      <p>
        This message board is a safe sharing space that aims to provide a
        safe and supportive platform for students to share their thoughts or stories. Inspired by VoiceBoard, an online newsletter platform
        founded by a Peaches & Cream intern, this message board strives to create an environment where anyone can communicate personal experiences
        and receive the help that they need.

        <br><br>

        We are dedicated to giving you a platform where you can share their personal stories and find support. Please know that this can by no
        means substitute professional help or advice.

        <br><br>

        If you are interested in learning more about mental wellness, check out the Peaches and  Cream Foundation mental wellness program. This program will will
        help you understand what mental wellness is and its importance. Check it out on the official Peaches and Cream Foundation website.

        <br><br>

        This message board is operated by the Peaches and Cream Foundation. We are always looking for support in order to continue providing the best resources to the
        community at home and abroad. If you would like to support the Peaches and Cream Foundation financially, consider donating on the official Peaches and Cream Foundation
        website or purchasing Peaches and Cream Foundation merchandise from our shop!
      </p>

<!------------------------------------------------------------------------ ABOUT LINKS (same section) ------------------------------------------------------------------------>
      <div class="hstack" id="about_links_wrapper">
        <a class="about_links" href="https://www.peachesandcream.org/" target="_blank">
          Official Website

          <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 72 72">
            <path d="M 43 12 C 40.791 12 39 13.791 39 16 C 39 18.209 40.791 20 43 20 L 46.34375 20 L 35.171875 31.171875 C 33.609875 32.733875 33.609875 35.266125 35.171875 36.828125 C 35.951875 37.608125 36.977 38 38 38 C 39.023 38 40.048125 37.608125 40.828125 36.828125 L 52 25.65625 L 52 29 C 52 31.209 53.791 33 56 33 C 58.209 33 60 31.209 60 29 L 60 16 C 60 13.791 58.209 12 56 12 L 43 12 z M 23 14 C 18.037 14 14 18.038 14 23 L 14 49 C 14 53.962 18.037 58 23 58 L 49 58 C 53.963 58 58 53.962 58 49 L 58 41 C 58 38.791 56.209 37 54 37 C 51.791 37 50 38.791 50 41 L 50 49 C 50 49.551 49.552 50 49 50 L 23 50 C 22.448 50 22 49.551 22 49 L 22 23 C 22 22.449 22.448 22 23 22 L 31 22 C 33.209 22 35 20.209 35 18 C 35 15.791 33.209 14 31 14 L 23 14 z"/>
          </svg>
        </a>


        <a class="about_links" href="https://peachesandcream.org/mental-wellness" target="_blank">
          Mental Wellness Program

          <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 72 72">
            <path d="M 43 12 C 40.791 12 39 13.791 39 16 C 39 18.209 40.791 20 43 20 L 46.34375 20 L 35.171875 31.171875 C 33.609875 32.733875 33.609875 35.266125 35.171875 36.828125 C 35.951875 37.608125 36.977 38 38 38 C 39.023 38 40.048125 37.608125 40.828125 36.828125 L 52 25.65625 L 52 29 C 52 31.209 53.791 33 56 33 C 58.209 33 60 31.209 60 29 L 60 16 C 60 13.791 58.209 12 56 12 L 43 12 z M 23 14 C 18.037 14 14 18.038 14 23 L 14 49 C 14 53.962 18.037 58 23 58 L 49 58 C 53.963 58 58 53.962 58 49 L 58 41 C 58 38.791 56.209 37 54 37 C 51.791 37 50 38.791 50 41 L 50 49 C 50 49.551 49.552 50 49 50 L 23 50 C 22.448 50 22 49.551 22 49 L 22 23 C 22 22.449 22.448 22 23 22 L 31 22 C 33.209 22 35 20.209 35 18 C 35 15.791 33.209 14 31 14 L 23 14 z"/>
          </svg>
        </a>


        <a class="about_links" href="https://www.google.com/" target="_blank" v-if="false">
          Shop Merchandise

          <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 72 72">
            <path d="M 43 12 C 40.791 12 39 13.791 39 16 C 39 18.209 40.791 20 43 20 L 46.34375 20 L 35.171875 31.171875 C 33.609875 32.733875 33.609875 35.266125 35.171875 36.828125 C 35.951875 37.608125 36.977 38 38 38 C 39.023 38 40.048125 37.608125 40.828125 36.828125 L 52 25.65625 L 52 29 C 52 31.209 53.791 33 56 33 C 58.209 33 60 31.209 60 29 L 60 16 C 60 13.791 58.209 12 56 12 L 43 12 z M 23 14 C 18.037 14 14 18.038 14 23 L 14 49 C 14 53.962 18.037 58 23 58 L 49 58 C 53.963 58 58 53.962 58 49 L 58 41 C 58 38.791 56.209 37 54 37 C 51.791 37 50 38.791 50 41 L 50 49 C 50 49.551 49.552 50 49 50 L 23 50 C 22.448 50 22 49.551 22 49 L 22 23 C 22 22.449 22.448 22 23 22 L 31 22 C 33.209 22 35 20.209 35 18 C 35 15.791 33.209 14 31 14 L 23 14 z"/>
          </svg>
        </a>
      </div>
    </section>

    <br>

<!--------------------------------------------------------------------- FREQUENTLY ASKED QUESTIONS --------------------------------------------------------------------->
    <section id="faq">
      <h1>Frequently Asked Questions</h1>

      <ul>
        <li>
          <h2>What is the message board?</h2>

          It is a place where students can anonymously submit their personal stories and by publishing these submissions,
          allows individuals to relate to one another and empower each other. It also strives to create an environment where
          talking about one’s struggles and finding help is normalized. Please know that this by no means can substitute professional help for anyone who needs it.
        </li>

        <li>
          <h2>What was the motivation for starting this message board?</h2>

          It was started because we wanted to create a platform where people can share their personal stories that are not only relatable,
          but also inspirational for each other. Through sharing, we hope that our community will be more interconnected and hence stronger.
        </li>

        <li>
          <h2>Are all submissions really anonymous? Why is this important?</h2>

          We are committed to keeping all submissions anonymous. When you submit through our website, we can only view your submission and
          we receive no information concerning your name or email address.

          <br><br>

          We want this to be a space where everyone feels comfortable with sharing their thoughts and staying anonymous is important
          in creating that safe, comfortable space for anyone to share their story.
        </li>

        <li>
          <h2>What kind of pieces are you looking for?</h2>

          We’re looking for submissions that reflect your personal experiences. Literary excellence is not our concern here.
          They can be in any form, from opinions to first-hand experiences.
        </li>

        <li>
          <h2>Does the staff edit the submissions?</h2>

          Staff will screen all submissions, but your submission will not be edited except for certain profanities which will be replaced by asterisks(*).
          This is to keep the submissions as original as possible and to preserve the authenticity of every submission. Please look at our submit page for more details.
        </li>

        <li>
          <h2>What if I have questions that are not answered here?</h2>

          Please feel free to contact us. We’d love to hear from you.
        </li>
      </ul>
    </section>


<!---------------------------------------------------------------------------- DISCLAIMER ---------------------------------------------------------------------------->
    <section>
      <h1>Disclaimer for Readers</h1>

      <p id="text-block">
        The Peaches and Cream Foundation provides this message board as a platform for individuals to share personal experiences and thoughts related to mental wellness.
        It is important for readers to understand that the information presented in submissions reflects solely the personal opinions and experiences of the
        contributors and not the Peaches and Cream Foundation.

        <br><br>

        We emphasize that the content shared on our message board is not intended to be a substitute for professional medical advice, diagnosis, or treatment.
        Always seek the advice of qualified healthcare professionals with any questions you may have regarding a medical condition. Do not disregard
        professional medical advice or delay in seeking it because of something you read on the Peaches and Cream Foundation message board.

        <br><br>

        In case of an emergency, immediately contact your doctor, go to the nearest emergency department, or call 911. If you are outside of the United States,
        please contact your local emergency services.

        <br><br>

        The Peaches and Cream Foundation does not guarantee the accuracy, completeness, or usefulness of any information on the message board and expressly
        disclaims liability for errors and omissions in the contents. We may review submissions but have no obligation to do so and may not necessarily
        remove or edit any content.

        <br><br>

        Readers should exercise their own judgment and conduct due diligence before relying on information found in the submissions. The Peaches and Cream
        Foundation shall not be liable for any damages resulting from your use of the message board or for any action you take based on the content provided here.

        <br><br>

        By accessing and reading submissions on the Peaches and Cream Foundation message board, you acknowledge and agree to this disclaimer and the
        Terms of Use governing the <router-link class="fake_link" to="/pnc/admin">website</router-link>.

        <br><br>

      </p>
    </section>

<!----------------------------------------------------------------------------- TERMS OF USE ----------------------------------------------------------------------------->
    <section>
      <h1>Terms of Use</h1>

      <p id="text-block">
        To comply with COPPA, children under 13 are not allowed to submit through this website.

        <br><br>

        The Peaches and Cream Foundation Message board is intended to provide individuals a safe platform for them to share their
        thoughts and stories and promote mental wellness. By using our message board, you signify your assent to these Terms of Use,
        which are put in place to keep everyone safe.

        <br><br>

        Peaches and Cream Foundation may revise and update these Terms of Use at any time. Your continued use of our website
        subsequent to any such post-changes will mean you accept those changes.

        <br><br>

        <span class="bold">The message board does not provide any professional advice.</span> All information presented here is solely reflective of the
        experiences and opinions of the submitters. Always seek the help of healthcare professionals if you have concerns or questions.

        <br><br>

        For emergencies, call your doctor or 911 immediately. If you are located outside the United States, call your local emergency line.

        <br><br>

        We take no responsibility for the content published and we do not review any submissions for completeness or accuracy.

        <br><br>

        We retain the right to select and publish the submissions. Additionally, we may disclose your submission only when we are legally required to do so.

        <br><br>

        In no event shall Peaches and Cream Foundation be liable for any damages resulting from the use or inability to use our website.

        <br><br>

        These Terms reflect the entire agreement between you and your access and usage of the Peaches and Cream Foundation message board.
        Our failure to exercise or enforce any right or provision of these Terms will not operate as a waiver of such right or provision.

        <br><br>

      </p>
    </section>

  </main>
</template>

<style scoped>
  section {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  ul {
    text-align: left;
  }

  li {
    margin-bottom: 2rem;
  }

  li::marker {
    font-weight: bold;
    font-size: 1.5rem;
  }

  h2 {
    margin: 0;
    padding: 0;
    color: var(--theme-secondary)
  }

  h1 {
    margin-bottom: 2rem;
    text-decoration: underline;
    color: var(--theme)
  }

  #about_links_wrapper {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }

  .about_links {
    background: var(--theme-secondary);
    padding: 0 8px;
    text-decoration: none;
    font-weight: bold;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .about_links svg {
    height: 2rem;
    aspect-ratio: 1;
  }

  #text-block {
    text-align: left;
  }
</style>

<script setup>

</script>