<template>
  <main>
    <h1>PNCF Error</h1>
    <p>Hello</p>
    <router-link to="/">Home</router-link>
  </main>
</template>

<style scoped>

</style>

<script setup>

</script>